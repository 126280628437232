import { useDataStore } from "./DataStoreProvider";
import Loading from "./Loading";
import parse from "html-react-parser";

function ImageDiv(item) {
  const arrayKeys = Object.keys(item.images);
  return arrayKeys.map((k) => {
    const image  = item.images[k];
    const source = 'https://s3.amazonaws.com/images.trianglemutualaid.org/'
                 + image.prefix + '/' + image.filename;
                 
    return (
      <div className="col-sm-2 order-1" key={image.id}>
        <img src={source} alt="Item" style={{"width":"100%","maxWidth":"100%","height":"auto"}} id={item.id} />
      </div>
    );
  });
}

function ItemList() {
  const { appData, setAppData } = useDataStore();
  
  const reList = (event) => {
    document.getElementById(event.target.dataset.id).src = "/loading-2.gif";
  
    fetch(appData.endpoint + 'item', {
      method : "PUT",
      body : JSON.stringify({
        token : appData.token, 
        user_id : appData.userId,
        itemId : event.target.dataset.id,
        orderId : appData.orderID,
      })      
    })
    .then((res) => {
      if (401 === res.status) {
        alert("Your login has expired");

        setAppData({
          ...appData,
          token: null, 
          user_id: "",
          page : "welcome",
          code : "",
        });  
      }
      else if (200 === res.status) {
        const newList = appData.items.reduce((prev, curr) => {
          if (event.target.dataset.id !== curr.id) {
            prev.push(curr);
          }
          
          return prev;
        }, []);
        
        if (newList.length) {
          setAppData({
            ...appData,
            items : newList,
          });
        }
        else {
          setAppData({
            ...appData,
            message : 'no items',
          });
        }
      }                
    })
    .catch((err) => console.error(err));
  };

  const row = (item) => {  
    return (
      <div className="row" style={{"marginTop":"30px"}} key={item.id}>
        <div className="col-sm-2 order-3">
          <button 
            className="btn btn-primary btn-sm"
            data-id={item.id}
            style={{"marginRight":"10px"}}
          >Delivered</button>
          <button 
            className="btn btn-secondary btn-sm"
            data-id={item.id}
            onClick={reList}
          >Re-List</button>
        </div>
        <div className="col-sm-4 order-2">
          <p><strong>{item.name}</strong></p>
          <p><strong>Location:</strong> {item.city}</p>
          <p>{item.description}</p>
        </div>
        <ImageDiv {...item} />
        <hr />
      </div>
    );        
  };  
  
  if ("no items" === appData.message) {
    return (
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col">
          Couldn't find items in this order. 
        </div>
      </div>    
    );
  }
  else if ("completed" === appData.message) {
    return <Loading />;
  }
  else if (appData.items.length) {    
    return appData.items.map((item) => {
      return row(item);
    });
  }
  else {
    fetch('https://d27q0fbbumtk5d.cloudfront.net/items.json', {
        method : "GET",
        cache : "no-cache",
    })
    .then((res) => {
      if (200 === res.status) {
        res.json().then((data) => {
          const order = appData.orders.reduce((prev, curr) => {
            if (curr.id === appData.orderID) {
              return curr;
            }
            else {
              return prev;
            }
          }, {});          
        
          const items = data.not_avail.reduce((prev, curr) => {
            if (order.items.includes(curr.id)) {
              prev.push(curr);
            }
            
            return prev;            
          }, []);
        
          if (items.length) {
            setAppData({
              ...appData,
              items : items,
            });          
          }
          else {
            setAppData({
              ...appData,
              message : 'no items',
            });
          }
        
        });
      }
      else if (401 === res.status) {
        alert('Required login missing or expired');
        setAppData({
          ...appData,
          page : 'welcome',
        });
      }
    })
    .catch((err) => console.error(err)); 
  
    return <Loading />;
  }
  
}

function OrderDetails() {
  const { appData, setAppData } = useDataStore();
  
  const order = appData.orders.reduce((prev, curr) => {
    if (curr.id === appData.orderID) {
      return curr;
    }
    else {
      return prev;
    }
  }, {});
  
  const formatContact = (contact) => {
    if (contact.match(/@/)) {
      return '<a href="mailto:' + contact + '">' + contact + '</a>';
    }
    else if (contact.match(/\d{10}/)) {
      const c = String(contact);
      return '<a href="sms:+1' + contact + '">(' + c.substring(0, 3) + ') ' + c.substring(3, 6) + '-' + c.substring(6) + '</a>';
    }
  };
  
  const complete = (event) => {
    fetch(appData.endpoint + 'order', {
      method : "DELETE",
      body : JSON.stringify({
        token : appData.token, 
        user_id : appData.userId,
        orderId : appData.orderID,
      })
    })
    .then((res) => {
      if (401 === res.status) {
        alert("Your login has expired");

        setAppData({
          ...appData,
          token: null, 
          user_id: "",
          page : "welcome",
          code : "",
        });  
      }
      else if (200 === res.status) {
        setAppData({
          ...appData,
          page : "donateOrReceive",
          items : [],
          message : "",
        });      
      }    
    })
    .catch((err) => console.error(err));
    
    setAppData({
      ...appData,
      message : "completed",
    });
  };
  
  const status = () => {
    if (appData.items.length) {
      return appData.items[0].status;
    }
    else {
      return "empty";
    }
  };
  
  return (
    <>
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col">
          <h1>Order Details</h1>
        </div>
      </div>
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-sm-2" style={{"marginTop":"10px"}}>
          Order ID {order.id}
          <br />
          <button className="btn btn-success" style={{"marginTop":"15px"}} onClick={complete}>
          All Delivered</button>
        </div>
        <div className="col-sm-3" style={{"marginTop":"10px"}}>
          {order.recipient.name}
        </div>
        <div className="col-sm-1" style={{"marginTop":"10px"}}>
          {order.recipient.address}, {order.recipient.town}
        </div>
        <div className="col-sm-2" style={{"marginTop":"10px"}}>
          Has car? {("yes" === order.recipient.car) ? 'Yes' : 'No'}
        </div>
        <div className="col-sm-2" style={{"marginTop":"10px"}}>
          {order.items.length} items
        </div>   
        <div className="col-sm-2" style={{"marginTop":"10px"}}>
          {parse(formatContact(order.recipient.contact))}
        </div>
      </div>
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col">
          {order.availability}
        </div>
        <div className="col">
          <span className="badge bg-info text-dark" style={{"fontSize":"1.2em"}}>Status: {status().replace(/_/, ' ')}</span>
        </div>
      </div>
      <ItemList />
    </>
  );
}

export default OrderDetails;