import { useDataStore } from "./DataStoreProvider";
import Loading from "./Loading";
import parse from "html-react-parser";

function IntakeList() {
  const { appData, setAppData } = useDataStore();

  const formatContact = (contact) => {
    if (contact.match(/@/)) {
      return '<a href="mailto:' + contact + '">' + contact + '</a>';
    }
    else if (contact.match(/\d{10}/)) {
      const c = String(contact);
      return '<a href="sms:+1' + contact + '">(' + c.substring(0, 3) + ') ' + c.substring(3, 6) + '-' + c.substring(6) + '</a>';
    }
    else {
      return contact;
    }
  };

  const showDetails = (event) => {
    setAppData({
      ...appData,
      items : [],
      intakeID : event.target.dataset.id,
      page : "intakeDetails",
    });
  };

  if ("no intakes" === appData.message) {
    return (
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col">
          No pending intakes. 
        </div>
      </div>    
    );
  }
  else if (appData.intakes.length) {
    return appData.intakes.map((intake) => {
      return (
        <div className="row" style={{"marginTop":"10px"}} key={intake.id}>
          <div className="col-sm-2" style={{"marginTop":"10px"}}>
            <button className="btn btn-sm btn-primary" data-id={intake.id} onClick={showDetails}>Intake ID {intake.id}</button>
          </div>
          <div className="col-sm-3" style={{"marginTop":"10px"}}>
            {intake.donor.name}
          </div>
          <div className="col-sm-1" style={{"marginTop":"10px"}}>
            {intake.donor.town}
          </div>
          <div className="col-sm-2" style={{"marginTop":"10px"}}>
            Has car? {("yes" === intake.donor.car) ? 'Yes' : 'No'}
          </div>
          <div className="col-sm-2" style={{"marginTop":"10px"}}>
            {intake.items.length} items
          </div>   
          <div className="col-sm-2" style={{"marginTop":"10px"}}>
            {parse(formatContact(intake.donor.contact))}
          </div>
        </div>
      );
    });
  }
  else {
    const url = appData.endpoint + 'intake?user_id=' + appData.userId;
    fetch(url, {
      method : "GET",
      cache : "no-cache",
      headers : {
        Authorization : appData.token
      }          
    })
    .then((res) => {
      if (200 === res.status) {
        res.json().then((data) => {
          if (data.intakes.length) {
            setAppData({
              ...appData,
              intakes : data.intakes.sort((a, b) => a.ts - b.ts),
            });          
          }
          else {
            setAppData({
              ...appData,
              message : "no intakes",
            });
          }        
        });
      }
      else if (401 === res.status) {
        alert('Required login missing or expired');
        setAppData({
          ...appData,
          page : 'welcome',
        });
      }
    })
    .catch((err) => console.error(err));
  
    return (
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col">
          <Loading />
        </div>
      </div>
    );
  }
}



function Intakes() {
  return (
    <>
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col">
          <h1>Pending Intakes</h1>
        </div>
      </div>
      <IntakeList />
    </>
  );

}


export default Intakes;