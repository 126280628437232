import DataStoreProvider, { useDataStore } from "./DataStoreProvider";
import './App.css';
import Welcome from "./Welcome";
import ReturningUser from "./ReturningUser";
import NewUser from "./NewUser";
import Loading from "./Loading";
import DonateOrReceive from "./DonateOrReceive";
import Confirm from "./Confirm";
import Hold from "./Hold";
import InputItems from "./InputItems";
import Address from "./Address";
import SendMessage from "./SendMessage";
import NotImplemented from "./NotImplemented";
import Items from "./Items";
import Cart from "./Cart";
import Checkout from "./Checkout";
import Orders from "./Orders";
import OrderDetails from "./OrderDetails";
import Intakes from "./Intakes";
import IntakeDetails from "./IntakeDetails";
import EditItem from "./EditItem";

function PageRouter() {
  const { appData } = useDataStore(); 
  
  switch (appData.page) {
    case "loading":
      return <Loading />;
    case "address":
      return <Address />;
    case "inputItems":
      return <InputItems />;
    case "hold":
      return <Hold />;
    case "confirm":
      return <Confirm />;
    case "donateOrReceive":
      return <DonateOrReceive />;
    case "returningUser":
      return <ReturningUser />;
    case "newUser":
      return <NewUser />;
    case "sendMessage":
      return <SendMessage />;
    case "notImplemented":
      return <NotImplemented />;
    case "welcome":
      return <Welcome />;
    case "items":
      return <Items />;
    case "cart":
      return <Cart />;
    case "checkout":
      return <Checkout />;    
    case "orders":
      return <Orders />;
    case "orderDetails":
      return <OrderDetails />;
    case "intakes":
      return <Intakes />;
    case "intakeDetails":
      return <IntakeDetails />;
    case "edit":
      return <EditItem />;
    
    default:
      return <Welcome />;
  }
}

function HomeMenu() {
  const { appData, setAppData } = useDataStore(); 
  
  const noAction = (event) => {
    event.preventDefault();
  };  

  const resourceHome = (event) => {
    event.preventDefault();
    
    setAppData({
      ...appData,
      page : "donateOrReceive",
      items : [],
      orders : [],
      message : "",
    });
  };
  
  if (appData.token) {
    return (
      <>
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="https://trianglemutualaid.org/">Hurricane Ian</a>
        </li>
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" onClick={resourceHome} href="./resources.html">Resource Sharing</a>
        </li>  
      </>
    );  
  }
  else {
    return (
      <>
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="https://trianglemutualaid.org/">Hurricane Ian</a>
        </li>
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" onClick={noAction} href="./resources.html">Resource Sharing</a>
        </li>  
      </>
    );  
  }
}

function App() {

  const noAction = (event) => {
    event.preventDefault();
  };
  

  return (
    <DataStoreProvider>
      <header>
        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
          <div className="container-fluid">
            <a className="navbar-brand" href="./resources.html" onClick={noAction}>Triangle Mutual Aid</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav me-auto mb-2 mb-md-0">
                <HomeMenu />
                <li className="nav-item">
                  <a className="nav-link" href="https://trianglemutualaid.org/photos.html">Photos</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://trianglemutualaid.org/blog.html">Blog</a>
                </li>
              </ul>
  
              <div className="d-flex" style={{"color": "blue", "fontSize": "1.5rem", "paddingRight": "25px"}}>
                <a rel="noreferrer" href="https://www.facebook.com/TriangleMutualAid" target="_blank"><i className="bi bi-facebook"></i></a>
              </div>
            </div>
          </div>
        </nav>
      </header>
      
      <main className="flex-shrink-0">
        <div className="container">
          <div className="container" style={{"height":"100%"}} id="inner-container">
            <PageRouter />      
          </div>   
        </div>
      </main>
      
    </DataStoreProvider>
  );
}

export default App;
