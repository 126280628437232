import { useDataStore } from "./DataStoreProvider";


function ImageDiv() {
  const { appData, setAppData } = useDataStore();
  
  const images = appData.items.reduce((prev, curr) => {
    if (curr.id === appData.itemId) {
      return curr.images;
    }
    else {
      return prev;
    }
  }, []);
  
  const arrayKeys = Object.keys(images);
  return arrayKeys.map((k) => {
    const image  = images[k];
    const source = 'https://s3.amazonaws.com/images.trianglemutualaid.org/'
                 + image.prefix + '/' + image.filename;
                 
    return (
      <div className="col-sm-2 order-1" key={image.id}>
        <img src={source} alt="Item" style={{"width":"100%","maxWidth":"100%","height":"auto"}} />
      </div>
    );
  });  
}

function TagInput() {
  const { appData, setAppData } = useDataStore();

  const formAction = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    setAppData({ ...appData, [key]: val });
  };

  const selectTag = (event) => {
    if (0 === event.target.options.selectedIndex) {
      return false;
    }
    else if (event.target.options.selectedIndex > appData.tags.length) {
      setAppData({
        ...appData,
        newTagName : "",
        newTag : true,
      });
    }
    else {
      let count = 0;
      const tag = appData.tags.reduce((prev, curr) => {
        count++;
        if (count === event.target.options.selectedIndex) {
          return curr;          
        }
        else {
          return prev;
        }
      }, '');
      
      let localItemTagList = appData.itemTags;
      localItemTagList.push(tag);
      setAppData({
        ...appData,
        itemTags : localItemTagList,
      });
    }
  };
  
  const addTag = () => {
    let localItemTagList = appData.itemTags;
    localItemTagList.push(appData.newTagName);
    setAppData({
      ...appData,
      itemTags : localItemTagList,
      newTag : "",
      newTagName : "",
    });
  };

  if ("undefined" === appData.newTag || !appData.newTag) {
    let tagChoices = [<option value="" key="-1" data-id="-1">(choose)</option>]; 
    let count      = 0; 
    appData.tags.map((tag) => {
      tagChoices.push(<option value={tag} key={count}>{tag}</option>);
      count++;
      return true;
    });
    tagChoices.push(<option value="newTag" key={count}>Suggest New Tag</option>);
  
    return (<select onChange={selectTag} className="form-control">{tagChoices}</select>);
  }
  else {
    return (
      <div className="input-group mb-3">
        <input type="text" className="form-control" onChange={formAction} name="newTagName" />
        <div className="input-group-append">
          <button className="btn btn-outline-secondary" type="button" onClick={addTag}>Add</button>
        </div>
      </div>
    );
  }
}

function EditItem() {
  const { appData, setAppData } = useDataStore();
  
  const formAction = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    setAppData({ ...appData, [key]: val });
  };

  const textAreaInput = (event) => {
    if (event.target.scrollHeight > 33) { 
      event.target.style.height = "5px";
      event.target.style.height = event.target.scrollHeight + "px";
      event.target.style['max-height'] = event.target.scrollHeight + "px";
    }
  };

  const updateItem = (event) => {
    event.preventDefault();
    
    if (appData.itemName) {
      document.getElementById('itemName').classList.remove('is-invalid');
    }
    else {
      document.getElementById('itemName').classList.add('is-invalid');
      return false;
    }
    
    setAppData({
      ...appData,
      page : "loading"
    });
    
//     let item_status = null;
//     if ('will_hold' === appData.status) {
//       item_status = 'holding';
//     }
//     else if ('yes' === appData.car) {
//       item_status = 'pending_delivery';
//     }
//     else {
//       item_status = 'pending_pickup';
//     }

    fetch(appData.endpoint + 'item', {
      method : "PUT",
      body : JSON.stringify({
        itemId : appData.itemId,
        tags : appData.itemTags,
        user_id : appData.userId,
        token : appData.token,
//         images : appData.images,
        item_name : appData.itemName,
        description : appData.description,
        bin : appData.bin,
        location : appData.location,
//         status : item_status,
//         donor_status : appData.status, 
//         intake : appData.intake,
      })
    })
    .then((res) => {
      if (200 === res.status) {
        const itemList = appData.items.map((item) => {
          if (item.id === appData.itemId) {
            const updatedItem = {
              ...item,
              tags : appData.itemTags,
              name : appData.itemName,
              description : appData.description,
              bin : appData.bin,
              location : appData.location, 
            };
            return updatedItem;
          }
          else {
            return item;
          }
        });
      
        let tags = appData.tags;
        appData.itemTags.map((tag) => {
          if (!tags.includes(tag)) {
            tags.push(tag);
          }
          return true;
        });
        
        setAppData({
          ...appData,
          items : itemList,
          tags : tags,
          page : "items",
          newTag : "",
          newTagName : "",
        });
      }
    }) 
    .catch((err) => console.error(err));  
  };  
  
  const clearTags = () => {
    setAppData({
      ...appData,
      itemTags : [],
    })
  }
  
  return (
    <>
      <div className="row" style={{"marginTop":"10px"}}>

        <div className="col-sm-8">

          <label htmlFor="itemName">Item Name (short)</label>
          <input 
            type="text" 
            id="itemName" 
            name="itemName" 
            className="form-control" 
            value={appData.itemName}
            onChange={formAction}
            style={{"marginBottom":"10px"}}
            required
          />
          <label htmlFor="description">Item Description (include e.g. size, type, condition)</label>
          <textarea
            rows={3}
            value={appData.description}
            onChange={formAction}
            onInput={textAreaInput}
            id="description"
            name="description"
            className="form-control"
            style={{"marginBottom":"15px"}}
          />
          
          <label htmlFor="bin">Bin Number</label>
          <input
            type="text"
            id="bin"
            name="bin"
            className="form-control"
            value={appData.bin}
            onChange={formAction}
            style={{"marginBottom":"15px"}}
          />
          <label htmlFor="location">Item Location</label>
          <input 
            type="text"
            id="location"
            name="location"
            className="form-control"
            value={appData.location}
            onChange={formAction}
            style={{"marginBottom":"15px"}}
          />
          
          <label htmlFor="tags">Tags: </label>
          {appData.itemTags.join(', ')}
          <br /><br />
          <button className="btn btn-warning" onClick={clearTags}>Clear Tags</button>
          <br /><br />
          Add Tag: 
          <TagInput />
          

          <button 
            className="btn btn-primary"
            onClick={updateItem}
            style={{"marginRight":"20px","marginTop":"20px"}}
          >Update Item</button>
          
        </div>
        
        <ImageDiv />

      </div>
    </>
  );  
  
  
}

export default EditItem;