import { useDataStore } from "./DataStoreProvider";

function Address() {
  const { appData, setAppData } = useDataStore(); 
  
  const formAction = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    setAppData({ ...appData, [key]: val });
  };
  
  const next = (event) => {
    event.preventDefault();
    
    if (!appData.address) {
      document.getElementById('address').classList.add("is-invalid");
      return false;
    }
    
    if (!appData.town) {
      document.getElementById('town').classList.add("is-invalid");
      return false;
    }
    
    setAppData({
      ...appData,
      page : "loading"
    });

    fetch(appData.endpoint + 'login', {
      method : "PUT",
      body : JSON.stringify({
        userId : appData.userId,
        token : appData.token,
        address : appData.address,
        town : appData.town,
      })
      .then((res) => {
        if (200 === res.status) {
          setAppData({
            ...appData,
            thumbnails : [],
            images : [],
            page : 'inputItems',
          });
        }
        else {
          alert('Error saving data');
          setAppData({
            ...appData,
            page : 'address',
          });                      
        }
      })
    }) 
    .catch((err) => console.error(err))   
  };

  return (
    <>
      <div className="row" style={{"marginTop":"20px"}}>
        <div className="col-md-12">
          {appData.text} 
        </div>
      </div>
    
      <div className="row" style={{"marginTop":"20px"}}>
        <div className="col-md-2">
          <label htmlFor="address">Address</label>
        </div>
        <div className="col-md-10">
          <input 
            type="text" 
            name="address" 
            id="address" 
            onChange={formAction} 
            className="form-control" 
            value={appData.address}
          />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"20px"}}>
        <div className="col-md-2">
          <label htmlFor="town">Town</label> (in central NC)
        </div>
        <div className="col-md-10">
          <input 
            type="text" 
            name="town" 
            id="town" 
            onChange={formAction} 
            className="form-control" 
            value={appData.town}
          />
        </div>
      </div>

      <div className="row" style={{"marginTop":"20px"}}>
        <div className="col-md-12">
          <button className="btn btn-primary" onClick={next}>Continue</button>
        </div>
      </div>
      
    </>
  );  
}

export default Address;