// https://stackoverflow.com/questions/39017087/how-to-implement-dropzone-js-to-upload-file-into-amazon-s3-server

// https://docs.dropzone.dev/configuration/events

import { useEffect } from 'react';
import { Dropzone } from "dropzone";
import { useDataStore } from "./DataStoreProvider";
import Loading from "./Loading";

Dropzone.autoDiscover = false;

function Thumbnails() {
  const { appData } = useDataStore();

  if (appData.thumbnails) {
    let i = 0;
    return appData.thumbnails.map(imgData => {
      i++; 
      return (<img key={i} src={imgData} style={{"marginBottom":"10px","width":"100%","maxWidth":"100%"}} alt="low resolution thumbnail" />);
    });  
  }
  else {
    return "";
  }
}

function Message() {
  const { appData, setAppData } = useDataStore();

  const doneAdding = (event) => {
    event.preventDefault();
    
    if ("will_hold" === appData.status) {
      setAppData({
        ...appData,
        page : "donateOrReceive",
        bin : "",
        location : "",
        thumbnails : [],
        images : [],
        itemName : "",
        description : "",
        item_status : "",
        my_dropzone : "",
        tagsChosen : [],
      });
    }
    else {
      if ("yes" === appData.car) {
        setAppData({
          ...appData,
          note : "",
          error : "",
          page : 'sendMessage',
          message : 'Use the field below to type a message to us, telling us a bit about your schedule over the next few days so we can arrange a time for you to bring items to our storage location.',
          bin : "",
          location : "",
          thumbnails : [],
          images : [],
          itemName : "",
          description : "",
          item_status : "",
          my_dropzone : "",
        });
      }
      else {
        setAppData({
          ...appData,
          note : "",
          error : "",
          page : 'sendMessage',
          message : 'Use the field below to type a message to us, telling us a bit about your schedule over the next few days so we can arrange a time to pick up your items, if we are able to find someone to do that.',
          bin : "",
          location : "",
          thumbnails : [],
          images : [],
          itemName : "",
          description : "",
          item_status : "",
          my_dropzone : "",
        });
      }
    }
  };
  
  if (appData.message) {
    return (
      <div className="alert alert-success" role="alert">
        {appData.message}
        &nbsp;
        <button 
          className="btn btn-warning"
          onClick={doneAdding}
          style={{"display":"block","marginTop":"15px"}}
        >Done Adding Items</button>        
      </div>
    );
  }
  else {
    return "";
  }
}

function Tags() {
  const { appData, setAppData } = useDataStore();
  
  const filter = (event) => {
    let chosen = [];
    
    if (appData.tagsChosen.includes(event.target.dataset.tag)) {
      chosen = appData.tagsChosen.reduce((prev, curr) => {
        if (curr !== event.target.dataset.tag) {
          prev.push(curr);
        }
        return prev;
      }, []);
    }
    else {
      chosen = appData.tagsChosen;
      chosen.push(event.target.dataset.tag);
    }
    
    setAppData({
      ...appData,
      tagsChosen : chosen,
    });            
  };
  
  if (appData.tags && appData.tags.length) {
    return appData.tags.map((tag) => {
      return <span key={tag} style={{"whiteSpace":"nowrap", "marginRight":"5px", "float":"left"}}><input type="checkbox" onChange={filter} checked={appData.tagsChosen.includes(tag)} data-tag={tag} />&nbsp;{tag}&nbsp;</span>;
    });  
  }
  else if (appData.my_dropzone) {
    fetch('https://d27q0fbbumtk5d.cloudfront.net/items.json', {
        method : "GET",
        cache : "no-cache",
    })
    .then((res) => {
      if (200 === res.status) {
        res.json().then((data) => {
          let tags = [];

          data.available.map((item) => {
            if (item.tags.length) {
              item.tags.map((tag) => {
                if (!tags.includes(tag)) {
                  tags.push(tag);
                }
                return true;
              });
            }
            return true;
          });
          
          data.not_avail.map((item) => {
            if (item.tags.length) {
              item.tags.map((tag) => {
                if (!tags.includes(tag)) {
                  tags.push(tag);
                }
                return true;
             });
            }
            return true;
          });
        
          setAppData({
            ...appData,
            items : data.available,
            allItems : data.available,
            tags : tags.sort(),
            tagsChosen : [],
          });          
        })
      }
      else if (401 === res.status) {
        alert('Required login missing or expired');
        setAppData({
          ...appData,
          page : 'welcome',
        });
      }      
    })
    .catch((err) => console.error(err)); 
  
    return (<div></div>);
    
  }
  else {
    return <Loading />;
  }
}

function AdminFields() {
  const { appData, setAppData } = useDataStore();

  const formAction = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    setAppData({ ...appData, [key]: val });
  };

  if (appData.admin) {
    return (
      <>
        <br className="clearfix" />
        <label htmlFor="bin">Bin Number</label>
        <input
          type="text"
          id="bin"
          name="bin"
          className="form-control"
          value={appData.bin}
          onChange={formAction}
          style={{"marginBottom":"15px"}}
        />
        <label htmlFor="location">Item Location</label>
        <input 
          type="text"
          id="location"
          name="location"
          className="form-control"
          value={appData.location}
          onChange={formAction}
          style={{"marginBottom":"15px"}}
        />
      </>
    );
  }
  else {
    return "";
  }
}

function InputItems() {
  const { appData, setAppData } = useDataStore();
  
  let my_dropzone = null;
  Dropzone.autoDiscover = false;
  
  const updateImages = (thumbs, imgs) => {
    setAppData({
      ...appData,
      thumbnails : thumbs,
      images : imgs,
    });
  };
  
  useEffect(() => {
    if (("undefined" === typeof(appData.my_dropzone) || "" === appData.my_dropzone) && 0 === document.getElementsByClassName('imageUpload').length) {
      my_dropzone = new Dropzone("div#uploadDiv", { 
        url: "/",
        headers : '',
        "clickable" : true,
        timeout: null,
        "autoProcessQueue" : false,
        "method" : "PUT",
        parallelUploads: 1,
        uploadMultiple: false,  
        accept : function(file, done) {
          const url = appData.endpoint + 'image?user_id=' + appData.userId + '&filename=' + file.name + '&type=' + file.type;
          fetch(url, {
            method : "GET",
            cache : "no-cache",
            headers : {
              Authorization : appData.token
            }          
          })
          .then((res) => {
            if (200 === res.status) {
              res.json().then((data) => {              
                my_dropzone.options.url = data.upload_info.url;
                file.uploadURL          = data.upload_info.url;
              
                my_dropzone.processFile(file);
              
                let imgDivs = document.getElementsByClassName('dz-image');
                let imgData = imgDivs[0].children[0].src;
              
                my_dropzone.removeAllFiles();
                
                let th = appData.thumbnails;
                th.push(imgData);
                
                let im = appData.images;
                im.push(data.upload_info.image_id);
                
                my_dropzone.destroy();
              
                updateImages(th, im);
              });      
            }
            else if (401 === res.status) {
              alert('Required login missing or expired');
              setAppData({
                ...appData,
                page : 'welcome',
              });
              done();
            }
            else if (400 === res.status) {
              alert('missing required information');
              done();
            }
          })
          .catch((err) => console.error(err))  
        },            
        sending :  function(file, xhr) {
          let _send = xhr.send;
          let _this = this;
          xhr.send = function () {
              _this.resizeImage(file, 600, null, 'contain', function (done) {
                  _send.call(xhr, done);
              })
          }
        },                  
      });
          
      setAppData({
        ...appData,
        my_dropzone : my_dropzone,
      });
    
    }
  
  });
  
  const formAction = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    setAppData({ ...appData, [key]: val });
  };

  const textAreaInput = (event) => {
    if (event.target.scrollHeight > 33) { 
      event.target.style.height = "5px";
      event.target.style.height = event.target.scrollHeight + "px";
      event.target.style['max-height'] = event.target.scrollHeight + "px";
    }
  };
  
  const addItem = (event) => {
    event.preventDefault();
    
    if (appData.itemName) {
      document.getElementById('itemName').classList.remove('is-invalid');
    }
    else {
      document.getElementById('itemName').classList.add('is-invalid');
      return false;
    }
    
    setAppData({
      ...appData,
      page : "loading"
    });
    
    let item_status = null;
    if ('will_hold' === appData.status) {
      item_status = 'holding';
    }
    else if ('yes' === appData.car) {
      item_status = 'pending_delivery';
    }
    else {
      item_status = 'pending_pickup';
    }

    fetch(appData.endpoint + 'item', {
      method : "POST",
      body : JSON.stringify({
        user_id : appData.userId,
        token : appData.token,
        images : appData.images,
        item_name : appData.itemName,
        description : appData.description,
        bin : appData.bin,
        location : appData.location,
        status : item_status,
        donor_status : appData.status, 
        intake : appData.intake,
        tags: appData.tagsChosen,
      })
    })
    .then((res) => {
      if (200 === res.status) {
        res.json().then((data) => {
          let items = appData.items;
          items.push(data.item_id);
      
          setAppData({
            ...appData,
            page : 'inputItems',
            thumbnails : [],
            images : [],
            items : items, 
            itemName : "",
            description : "",
            message : "Item successfully stored. You may enter another item or click the yellow \"Done Adding Items Button\"",
            my_dropzone : "",
            intake : data.intake_id,
            tagsChosen : [],
          });        
        });
      }
    }) 
    .catch((err) => console.error(err));  
  };
  
  return (
    <>
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-sm-12">
          <h1>Items to Donate</h1>
          <Message />
        </div>
      </div>
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-sm-12">
          Please fill out as much information as you can and upload photos if you are 
          able. It will be much easier if each person does as much of this work as they 
          can rather than relying on folks at our distribution site to do it all.  
        </div>
      </div>
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-sm-8">
          <div id="uploadDiv" style={{"padding":"8px","height":"200px","width":"100%","marginBottom":"10px","border":"2px dashed #000000","position":"relative","zIndex":"2"}}>
          Drop image here or click <strong>just below this text (inside the dashed box)</strong> to choose an image to upload. <br />
          You can upload multiple images of the same item if needed.
          </div>
          <label htmlFor="itemName">Item Name (short)</label>
          <input 
            type="text" 
            id="itemName" 
            name="itemName" 
            className="form-control" 
            value={appData.itemName}
            onChange={formAction}
            style={{"marginBottom":"10px"}}
            required
          />
          <label htmlFor="description">Item Description (include e.g. size, type, condition)</label>
          <textarea
            rows={3}
            value={appData.description}
            onChange={formAction}
            onInput={textAreaInput}
            id="description"
            name="description"
            className="form-control"
            style={{"marginBottom":"5px"}}
          />
          
          <p>Check boxes for any tags which apply to this item</p>
          <div style={{"marginBottom":"10px"}}><Tags /></div>
          
          <AdminFields />
          
          <br className="clearfix" />
          <button 
            className="btn btn-primary"
            onClick={addItem}
            style={{"marginRight":"20px"}}
          >Add Item</button>
          </div>
          
        </div>
        <div className="col-sm-4" id="thumbnails">
          <Thumbnails />
          <canvas id="canvas"></canvas>
        
      </div>
    </>
  );  
}

export default InputItems;