import { useDataStore } from "./DataStoreProvider";

function ImageDiv(images) {
  const arrayKeys = Object.keys(images);
  return arrayKeys.map((k) => {
    const image  = images[k];
    const source = 'https://s3.amazonaws.com/images.trianglemutualaid.org/'
                 + image.prefix + '/' + image.filename;
                 
    return (
      <div className="col-sm-2" key={image.id}>
        <img src={source} alt="Item" style={{"width":"100%","maxWidth":"100%","height":"auto"}} />
      </div>
    );
  });
}


function ShowList() {
  const { appData, setAppData } = useDataStore();    

  const removeFromCart = (event) => {
  
    let cart  = [];
  
    let items = appData.items;
  
    appData.cart.map((item) => {
      if (item.id === event.target.dataset.id) {
        return items.push(item);
      }
      else {
        return cart.push(item);
      }
    });    

    setAppData({
      ...appData,
      cart : cart,
      items : items,
    });
  };
  
  const row = (item) => {  
    return (
      <div className="row" style={{"marginTop":"10px"}} key={item.id}>
        <div className="col-sm-2">
          <button 
            className="btn btn-primary btn-sm"
            onClick={removeFromCart}
            data-id={item.id}
          >Remove From Cart</button>
        </div>
        <div className="col-sm-4">
          <p><strong>{item.name}</strong></p>
          <p>{item.description}</p>
        </div>
        <ImageDiv {...item.images} />
      </div>
    );        
  };
  
  if (!appData.cart.length) {
    return (
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-sm-12">
          <em>No items to display</em>
        </div>
      </div>
    )
  }
  else {    
    return appData.cart.map((item) => {
      return row(item);
    });
  }
};

function Cart() {
  const { appData, setAppData } = useDataStore();
  
  const checkout = (event) => {
    event.preventDefault();

    setAppData({
      ...appData,
      page : "checkout",
    });
  };
  
  const height = document.getElementById('root').offsetHeight - 60;
  
  const seeItems = (event) => {
    setAppData({
      ...appData,
      page : "items",
    });
  };
  
  return (
    <>
      <div id="headerRow" className="row" style={{"marginTop":"10px","marginBottom":"10px","position":"relative","zIndex":"1"}}>
        <div className="col-sm-8">
          <h1>Items in your Cart</h1>
          <p>Scroll through items to review. Remove any items you do not need.          
          Checkout when you are through.</p>
        </div>
        <div className="col">
          <button className="btn btn-secondary btn-lg" onClick={seeItems}>
            Items 
          </button>
        </div>
        <div className="col">
          <button className="btn btn-secondary btn-lg  float-end" onClick={checkout}>Checkout</button>
        </div>
      </div>
      <div style={{"width":"100%","overflowY":"auto","overflowX":"hidden","height":height}}>
        <ShowList />
      </div>
    </>
  );
}

export default Cart;