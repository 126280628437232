import { useDataStore } from "./DataStoreProvider";

function ReturningUser() {
  const { appData, setAppData } = useDataStore(); 
  
  const formAction = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    setAppData({ ...appData, [key]: val });
  };
  
  const next = (event) => {
    event.preventDefault();

    if (!appData.toSend) {
      document.getElementById('toSend').classList.add("is-invalid");
      return false;
    }

    setAppData({
      ...appData,
      page : "loading"
    });
    
    const url = appData.endpoint + 'login?toSend=' + appData.toSend;
    fetch(url, {
      method : "GET",
    })
    .then((res) => {
      if (200 === res.status) {
        res.json().then((data) => {
          setAppData({
            ...appData,
            page : 'confirm',
            userId : data.userId,
            code : "",
          });        
        });      
      }
      else if (401 === res.status) {
        alert('user not found');
        setAppData({
          ...appData,
          page : 'welcome',
        });                
      }
      else {
        alert('server error');
        setAppData({
          ...appData,
          page : 'returningUser',
        });        
      }
    })
    .catch((err) => console.error(err))  
    
  };

  return (
    <>
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-sm-2">
          Email or Text Number:
        </div>
        <div className="sm-col-10">
          <input 
            type="text" 
            name="toSend"
            id="toSend"
            value={appData.toSend}
            onChange={formAction}
            className="form-control"
          />
        </div>
      </div>
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-sm-12">
          <button className="btn btn-primary" onClick={next}>Continue</button>
        </div>
      </div>
    </>
  );  
}

export default ReturningUser;