import { useDataStore } from "./DataStoreProvider";

function Message() {
  const { appData } = useDataStore();
  
  if (appData.error && "" !== appData.error) {
    return (
      <div className="alert alert-danger" role="alert">
        {appData.error}
      </div>
    );
  }
  else {
    return "";
  }
}

function SendMessage() {
  const { appData, setAppData } = useDataStore();
  
  const formAction = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    setAppData({ ...appData, [key]: val });
  };
  
  const textAreaInput = (event) => {
    if (event.target.scrollHeight > 33) { 
      event.target.style.height = "5px";
      event.target.style.height = event.target.scrollHeight + "px";
      event.target.style['max-height'] = event.target.scrollHeight + "px";
    }
  }  

  const send = (event) => {
    event.preventDefault();
    
    setAppData({
      ...appData,
      page : "loading"
    });
    
    
    fetch(appData.endpoint + "message", {
      method : "POST",
      body : JSON.stringify({
        user_id : appData.userId,
        token : appData.token,
        note : appData.note,
        donor_status : appData.status, 
        intake : appData.intake,
      })
    })
    .then((res) => {
      if (200 === res.status) {
        setAppData({
          ...appData,
          page : "donateOrReceive",
          message : "",
        });
      }
      else {
        res.json().then((data) => {
          setAppData({
            ...appData,
            error : data.message,
          });
        });
      }
    })
    .catch((err) => {
      setAppData({
        ...appData,
        error : err,
      });
    })
  };
    
  return (
    <>
      <div style={{"marginTop":"10px"}}>
        <Message />
        <h1>Send us a message</h1>
        {appData.message}
      </div>
      <div style={{"marginTop":"10px"}}>
        <textarea
          rows={5}
          value={appData.note}
          onChange={formAction}
          onInput={textAreaInput}
          id="note"
          name="note"
          className="form-control"
        />      
      </div>
      <div style={{"marginTop":"10px"}}>
          <button 
            className="btn btn-primary"
            onClick={send}
          >Send Message</button>      
      </div>
    </>
  );
}

export default SendMessage;