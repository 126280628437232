import { useDataStore } from "./DataStoreProvider";

function Welcome() {
  const { appData, setAppData } = useDataStore(); 

  const login = (event) => {
    setAppData({
      ...appData,
      page : 'returningUser',
      toSend : "",
    });
  };
  
  const newUser = (event) => {
    setAppData({
      ...appData,
      page : 'newUser',
      preferComms : "",
      car : "",
      userName : "",
      email : "",
      phone : "",
      address : "",
      town : "",
    });
  };

  return (<>
    <div className="row" style={{"marginBottom":"20px"}}>
      <div className="col-6">
        <button className="btn btn-primary" onClick={login}>Returning User</button>
      </div>
      <div className="col-6">
        <button className="btn btn-primary float-end" onClick={newUser}>New User</button>
      </div>
    </div>

    <h1>Welcome to Triangle Mutual Aid resource sharing.</h1>
    <p>If you've been here before and have your information registered in our system, 
    you can click the "Returning User" button and continue.</p>
    <p>For newcomers, please read these few words so you can understand our approach, and so you 
    won't be surprised by our requests in the future.</p>
    <p>Donating items is a common act of charity. "Mutual Aid" is philosophy which 
    consciously attempts to operate with a specific set of principles, some of which stand 
    in contrast to the way some charity organizations operate.</p>
    
    <p><strong>Quality</strong>. Valuing what is "Mutual" implies a belief that all people
    share the same inherent worth. We do not ever interpret someone's need for aid as a 
    character flaw. Please do not donate items you yourself wouldn't be proud to wear or use. 
    Clothing that is stained or items which are damaged will likely be taken to a thrift 
    store if they still have value or disposed of. Reusing items is a great thing for people 
    and the planet, but we intend to provide items which uphold everyone's dignity.</p>
    
    <p><strong>Involvement</strong>. This is another way in which "Mutual" shows up: once 
    we have your contact info, and until you ask to be removed from the system, we will ask
    everyone for their help in making deliveries and pickups, working at distro sites, etc. 
    We will also invite all participants to have a voice in decision making (see also the 
    last principle below). Everyone one of us has, or will at some point in the future 
    need aid from someone. Likewise everyone who receives aid also has important things to 
    contribute to the community.</p>
    
    <p><strong>Gatekeeping</strong>. We will never use social filters to decide who is 
    worthy of receiving aid. Not only will we never descriminate by race, religion, nationality,
    gender or sexuality, we also embrace the philosophy of harm reduction and will never 
    require sobriety in providing aid.</p>
    
    <p><strong>Prioritization</strong>. When doing outreach, or any other time we are 
    forced to prioritize limited resources, we strive to reach those most in need. In 
    addition to obvious cases, such as sending resources to those who have just survived a 
    natural or personal disaster, prioritization means thinking about who lacks access to 
    other forms of aid (such as government aid available to homeowners but not renters) and 
    who is a member of a historically underserved or oppressed community.</p>
    
    <p><strong>Radical</strong>. In the sense that the word "radical" is derived from Latin,  
    meaning "root", Mutual Aid acknowledges that human problems such as hunger and poverty 
    have their root cause in societal constructs such as capitalism, racism, sexism, homophobia, 
    and other forms of oppression. A truly successful charity might succeed in 
    meeting everyone's basic needs by redistributing the excesses donated by the wealthy, 
    thus relieving social pressure for change and helping to maintain an unjust status quo.
    Mutual Aid searches for ways to address the underlying problems which create the need 
    for aid. One manifestation of this goal is our work to invite everyone in to building 
    new social institutions and new economies based on values of equity, ecology and freedom.</p>
    
    <p><strong>Distributed</strong>. When needs are large in scope and/or immeditate
    enough to require drawing resources from outside of a local area, we have and will
    continue to collaborate with other local and regional mutual aid groups to direct
    donations beyond our local footprint in central NC. Words sometimes used to describe this model of
    organizing are <a  target="_blank" rel="noreferrer"
    href="https://iopn.library.illinois.edu/scalar/constructing-solidarities-for-a-humane-urbanism/possibilities-of-trans-local-organizing"> 
    trans-local</a> and federation; the goal is to mobilize larger, broadly sourced
    resources while keeping decision-making rooted in the impacted communities.</p>
    
    <p>
      <em>
        Our limited storage space and labor are donated. The software was donated by 
        <a href="mailto:devin@nacredata.com" target="_blank" rel="noreferrer">NacreData L.L.C.</a> &nbsp;
        Perhaps you would like to <a href="mailto:into@trianglemutualaid.org" rel="noreferrer" target="_blank">donate some design work</a>? 
         The ongoing material costs for the project (web hosting, email and 
        text services, storage bins, as well as some of the transportation costs) are currently supported
        by <a href="https://www.patreon.com/trianglemutualaid" rel="noreferrer" target="_blank">subscriptions to this Patreon account</a>. 
      </em>
    </p>
    
    <div className="row" style={{"marginTop":"20px"}}>
      <div className="col-6">
        <button className="btn btn-primary" onClick={login}>Returning User</button>
      </div>
      <div className="col-6">
        <button className="btn btn-primary float-end" onClick={newUser}>New User</button>
      </div>
    </div>
  </>);
}

export default Welcome;