import { useDataStore } from "./DataStoreProvider";

function Checkout() {
  const { appData, setAppData } = useDataStore();
  
  const formAction = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    setAppData({ ...appData, [key]: val });
  };  
  
  const send = (event) => {
    event.preventDefault();
    
    setAppData({
      ...appData,
      page : "loading"
    });

    let eno_items = [];
    
    let held = [];
    let held_items = {};

    appData.cart.map((item) => {
      if ('Eno House' === item.location) {
        eno_items.push(item.id);
      }
      else {
        if (!held.includes(item.donor)) {
          held.push(item.donor);   
          held_items[item.donor] = [];       
        }
        held_items[item.donor].push(item.id);
      }
    
      return true;
    });
  
    fetch(appData.endpoint + 'order', {
      method : "POST",
      body : JSON.stringify({
        user_id : appData.userId,
        token : appData.token,
        inventory_items : eno_items,
        holders : held,
        items_held : held_items,
        availability : appData.availability,
      })
    })
    .then((res) => {
      setAppData({
        ...appData,
        items : [],
        message : "",
        cart : [],
        page : "donateOrReceive",
      });
    })
    .catch((err) => console.error(err));
    
  };
  
  if (0 === appData.cart.length) {
    return (
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-sm-12">
          <em>No items to display</em>
        </div>
      </div>
    );  
  }
  else {
    if (appData.car) {
      return (
        <>
          <div style={{"marginTop":"10px"}}>
            <h1>Send us a message</h1>
            <p>Let us know something about your schedule so we can arange time(s) for you 
            to pick up your items at the place or places they are at.</p>
          </div>
          <div style={{"marginTop":"10px"}}>
            <textarea
              rows={5}
              value={appData.availability}
              onChange={formAction}
              id="availability"
              name="availability"
              className="form-control"
            />      
          </div>
          <div style={{"marginTop":"10px"}}>
          By continuting and completing checkout, I agree that any items I receive from Triangle 
          Mutual Aid are for the use of myself or other people in need in my immediate community
          and not for resale. Any sale of items received from Triangle Mutual Aid is a violation
          of terms of agreement.       
          </div>
          
          <div style={{"marginTop":"20px"}}>
              <button 
                className="btn btn-primary"
                onClick={send}
              >Checkout &amp; Send Message</button>      
          </div>
        </>
      );
    }
    else {
      send();
    }
  
  }

}

export default Checkout;