import { useDataStore } from "./DataStoreProvider";
import Loading from "./Loading";
import parse from "html-react-parser";

function OrderList() {
  const { appData, setAppData } = useDataStore();
    
  const formatContact = (contact) => {
    if (contact.match(/@/)) {
      return '<a href="mailto:' + contact + '">' + contact + '</a>';
    }
    else if (contact.match(/\d{10}/)) {
      const c = String(contact);
      return '<a href="sms:+1' + contact + '">(' + c.substring(0, 3) + ') ' + c.substring(3, 6) + '-' + c.substring(6) + '</a>';
    }
    else {
      return contact;
    }
  };
  
  const showDetails = (event) => {
    setAppData({
      ...appData,
      items : [],
      orderID : event.target.dataset.id,
      page : "orderDetails",
    });
  };

  if ("no orders" === appData.message) {
    return (
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col">
          No pending orders. 
        </div>
      </div>    
    );
  }
  else if (appData.orders.length) {
    return appData.orders.map((order) => {
      return (
        <div className="row" style={{"marginTop":"10px"}} key={order.id}>
          <div className="col-sm-2" style={{"marginTop":"10px"}}>
            <button className="btn btn-sm btn-primary" data-id={order.id} onClick={showDetails}>Order ID {order.id}</button>
          </div>
          <div className="col-sm-3" style={{"marginTop":"10px"}}>
            {order.recipient.name}
          </div>
          <div className="col-sm-1" style={{"marginTop":"10px"}}>
            {order.recipient.town}
          </div>
          <div className="col-sm-2" style={{"marginTop":"10px"}}>
            Has car? {("yes" === order.recipient.car) ? 'Yes' : 'No'}
          </div>
          <div className="col-sm-2" style={{"marginTop":"10px"}}>
            {order.items.length} items
          </div>   
          <div className="col-sm-2" style={{"marginTop":"10px"}}>
            {parse(formatContact(order.recipient.contact))}
          </div>
        </div>
      );
    });
  }
  else {
    const url = appData.endpoint + 'order?user_id=' + appData.userId;
    fetch(url, {
      method : "GET",
      cache : "no-cache",
      headers : {
        Authorization : appData.token
      }          
    })
    .then((res) => {
      if (200 === res.status) {
        res.json().then((data) => {
          if (data.orders.length) {
            setAppData({
              ...appData,
              orders : data.orders.sort((a, b) => a.ts - b.ts),
            });          
          }
          else {
            setAppData({
              ...appData,
              message : "no orders",
            });
          }        
        });
      }
      else if (401 === res.status) {
        alert('Required login missing or expired');
        setAppData({
          ...appData,
          page : 'welcome',
        });
      }
    })
    .catch((err) => console.error(err));
  
    return (
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col">
          <Loading />
        </div>
      </div>
    );
  }
};

function Orders() {
  return (
    <>
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col">
          <h1>Orders</h1>
        </div>
      </div>
      <OrderList />
    </>
  );
}

export default Orders;