import { useDataStore } from "./DataStoreProvider";

function NewUser() {
  const { appData, setAppData } = useDataStore();

  const formAction = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    setAppData({ ...appData, [key]: val });
  };
  
  
  const next = (event) => {
    event.preventDefault();
    
    if (!appData.userName) {
      document.getElementById('userName').classList.add("is-invalid");
      alert('Please let us know what your name is.');
      return false;
    }
    
    if (!appData.email && !appData.phone) {
      document.getElementById('email').classList.add("is-invalid");
      document.getElementById('phone').classList.add("is-invalid");
      alert('We need to have a way to get in touch with you.');      
      return false;
    }
    
    if (!appData.car) {
      alert("Do you have access to a vehicle to pick up or drop off resources?");
      return false;
    }
    
    if (!appData.agree) {
      document.getElementById('terms').style.color = "red";
      alert("You must agree to the terms to participated");
      return false;
    }
    
    setAppData({
      ...appData,
      page : "loading"
    });
    
    fetch(appData.endpoint + 'login', {
      method : "POST",
      body   : JSON.stringify({
        userName : appData.userName,
        email : appData.email,
        phone : appData.phone,
        preferComms : appData.preferComms,
        address : appData.address,
        town : appData.town,
        car : appData.car,
      })
    })
    .then((res) => {
      if (200 === res.status) {
        res.json().then((data) => {
          setAppData({
            ...appData,
            page : 'donateOrReceive',
            token : data.token,
            userId : data.id
          });        
        });      
      }
      else {
        alert('error saving information');
        setAppData({
          ...appData,
          page : 'newUser',
        });        
      }
    })
    .catch((err) => console.error(err))  
  };
   
  return (
    <>
      <div className="row" style={{"marginTop":"20px"}}>
        <div className="col-md-2">
          <label htmlFor="userName">Your Name</label>
        </div>
        <div className="col-md-10">
          <input 
            type="text" 
            name="userName" 
            id="userName" 
            onChange={formAction} 
            className="form-control" 
            value={appData.userName}
            required 
          />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"20px"}}>
        <div className="col-md-2">
          <label htmlFor="email">Your Email</label>
        </div>
        <div className="col-md-10">
          <input 
            type="email" 
            name="email" 
            id="email" 
            onChange={formAction} 
            className="form-control" 
            value={appData.email}
            required 
          />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"20px"}}>
        <div className="col-md-2">
          <label htmlFor="phone">Your Phone</label>
        </div>
        <div className="col-md-10">
          <input 
            type="text" 
            name="phone" 
            id="phone" 
            onChange={formAction} 
            className="form-control" 
            value={appData.phone}
            required 
          />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"20px"}}>
        <div className="col-md-12">
          <label htmlFor="preferComms">
            Do you prefer communication by email or text message? &nbsp; 
          </label>
          <input 
            type="radio" 
            name="preferComms" 
            value="email" 
            checked={"email" === appData.preferComms} 
            onChange={formAction} 
          /> &nbsp; email &nbsp; 
          
          <input 
            type="radio" 
            name="preferComms" 
            value="text" 
            checked={"text" === appData.preferComms} 
            onChange={formAction} 
          /> &nbsp; text
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"20px"}}>
        <div className="col-md-2">
          <label htmlFor="address">Address</label>
        </div>
        <div className="col-md-10">
          <input 
            type="text" 
            name="address" 
            id="address" 
            onChange={formAction} 
            className="form-control" 
            value={appData.address}
          />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"20px"}}>
        <div className="col-md-2">
          <label htmlFor="town">Town</label> (in central NC)
        </div>
        <div className="col-md-10">
          <input 
            type="text" 
            name="town" 
            id="town" 
            onChange={formAction} 
            className="form-control" 
            value={appData.town}
          />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"20px"}}>
        <div className="col">
          <label htmlFor="car">Do you have access to a vehicle to pick up or drop off resources?</label> &nbsp; 
          <input 
            type="radio" 
            name="car" 
            value="yes" 
            checked={"yes" === appData.car} 
            onChange={formAction} 
          /> &nbsp; yes &nbsp; 
          
          <input 
            type="radio" 
            name="car" 
            value="no" 
            checked={"no" === appData.car} 
            onChange={formAction} 
          /> &nbsp; no
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"20px"}}>
        <div className="col" id="terms">
          <input name="agree" type="checkbox" checked={appData.agree} value="1" onClick={formAction} onChange={formAction} />
          &nbsp; <strong>I agree</strong><br />
          By checking this box and continuting I agree that any items I receive from Triangle 
          Mutual Aid are for the use of myself or other people in need in my immediate community
          and not for resale. Any sale of items received from Triangle Mutual Aid is a violation
          of terms of agreement. &nbsp;           
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"20px","marginBottom":"40px"}}>
        <div className="col-md-12">
          <button className="btn btn-primary" onClick={next}>Continue</button>
        </div>
      </div>
    </>
   );
}

export default NewUser;