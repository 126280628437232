import { useDataStore } from "./DataStoreProvider";
import Loading from "./Loading";


function ImageDiv(images) {
  const arrayKeys = Object.keys(images);
  return arrayKeys.map((k) => {
    const image  = images[k];
    const source = 'https://s3.amazonaws.com/images.trianglemutualaid.org/'
                 + image.prefix + '/' + image.filename;
                 
    return (
      <div className="col-sm-2 order-1" key={image.id}>
        <img src={source} alt="Item" style={{"width":"100%","maxWidth":"100%","height":"auto"}} />
      </div>
    );
  });
}


function ShowList() {
  const { appData, setAppData } = useDataStore();    

  const addToCart = (event) => {
  
    let cart  = appData.cart;
  
    let items = [];
  
    appData.items.map((item) => {
      if (item.id === event.target.dataset.id) {
        return cart.push(item);
      }
      else {
        return items.push(item);
      }
    });    

    setAppData({
      ...appData,
      cart : cart,
      items : items,
    });
  };
  
  const edit = (event) => { 
    const item = appData.items.reduce((prev, curr) => {
      if (event.target.dataset.id === curr.id) {
        return curr;
      }
      else {
        return prev;
      }
    }, {});
    

    setAppData({
      ...appData,
      page : 'edit',
      itemId : item.id,
      images : item.images,
      itemName : item.name,
      description : item.description,
      bin : item.bin,
      location : item.location,
      itemTags : item.tags,
    });
  };
    
  const row = (item) => {  
  
    const tags = () => {
      if (item.tags.length) {
        return 'Tags: ' + item.tags.join(', ');
      }
      else {
        return "";
      }
    };
    
    const editBtn = () => {
      if (appData.admin) {
        return (
          <button 
            className="btn btn-secondary btn-sm" 
            onClick={edit}
            data-id={item.id}
            style={{"marginLeft":"10px"}}
          >Edit</button>
        );
      }
      else {
        return "";
      }
    };
    
    return (
      <div className="row" style={{"marginTop":"30px"}} key={item.id}>
        <div className="col-sm-2 order-3">
          <button 
            className="btn btn-primary btn-sm"
            onClick={addToCart}
            data-id={item.id}
          >Add To Cart</button>
          {editBtn()}
        </div>
        <div className="col-sm-4 order-2">
          <p><strong>{item.name}</strong></p>
          <p><strong>Location:</strong> {item.city}</p>
          <p>{item.description}</p>        
          <p>{tags()}</p>
        </div>
        <ImageDiv {...item.images} />
        <hr />
      </div>
    );        
  };
  
  if (appData.cart.length && !appData.items.length) {
    return (
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-sm-12">
          <em>No more items to display</em>
        </div>
      </div>
    );
  }
  else if ((appData.allItems && appData.allItems.length) && !appData.items.length) {
    return (
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-sm-12">
          <em>No items match this combination of tags</em>
        </div>
      </div>
    );
  }
  else if (appData.items.length) {    
    return appData.items.map((item) => {
      return row(item);
    });
  }
  else {
    fetch('https://d27q0fbbumtk5d.cloudfront.net/items.json', {
        method : "GET",
        cache : "no-cache",
    })
    .then((res) => {
      if (200 === res.status) {
        res.json().then((data) => {
          let tags = [];
          data.available.map((item) => {
            if (item.tags.length) {
              item.tags.map((tag) => {
                if (!tags.includes(tag)) {
                  tags.push(tag);
                }
                return true;
              });
            }
            return true;
          });
          
          data.not_avail.map((item) => {
            if (item.tags.length) {
              item.tags.map((tag) => {
                if (!tags.includes(tag)) {
                  tags.push(tag);
                }
                return true;
             });
            }
            return true;
          });
        
          setAppData({
            ...appData,
            items : data.available,
            allItems : data.available,
            tags : tags.sort(),
            tagsChosen : [],
          });
        });
      }
      else if (401 === res.status) {
        alert('Required login missing or expired');
        setAppData({
          ...appData,
          page : 'welcome',
        });
      }
    })
    .catch((err) => console.error(err)); 
  
    return <Loading />;
  }
};

function Items() {
  const { appData, setAppData } = useDataStore();
  
  const checkout = (event) => {
    event.preventDefault();
    
    setAppData({
      ...appData,
      page : "checkout",
    });
  };
  
  const height = document.getElementById('root').offsetHeight - 60;
  
  const showCart = () => {
    setAppData({
      ...appData,
      page : "cart",
    });
  };
  
  const hideTop = () => {
    setAppData({
      ...appData,
      topTitleClass : "col-md-8 d-none",
    });
  };
  
  const toggleTags = () => {
    if ("row d-none" === appData.tagsShown) {
      setAppData({
        ...appData,
        tagsShown : "row",
      });
    }
    else {
      setAppData({
        ...appData,
        tagsShown : "row d-none",
      });
    }
  };
  
  const filter = (event) => {
    let chosen = [];
    
    if (appData.tagsChosen.includes(event.target.dataset.tag)) {
      chosen = appData.tagsChosen.reduce((prev, curr) => {
        if (curr !== event.target.dataset.tag) {
          prev.push(curr);
        }
        return prev;
      }, []);
    }
    else {
      chosen = appData.tagsChosen;
      chosen.push(event.target.dataset.tag);
    }
    
    if (chosen.length) {
      const chosenSet = appData.allItems.reduce((prev, curr) => {
        const included = chosen.reduce((p, c) => {
          if (curr.tags.includes(c)) {
            return p;
          }        
          else {
            return false;
          }  
        }, true);
        
        if (included) { prev.push(curr); }
        
        return prev;
      }, []);
      
      setAppData({
        ...appData,
        items : chosenSet,
        tagsChosen : chosen,
      });      
    }
    else {
      setAppData({
        ...appData,
        items : appData.allItems,
        tagsChosen : chosen,
      });
    }
  };
  
  const tagChoices = () => {
    return appData.tags.map((tag) => {
      return <span key={tag} style={{"whiteSpace":"nowrap", "marginRight":"5px", "float":"left"}}><input type="checkbox" onChange={filter} checked={appData.tagsChosen.includes(tag)} data-tag={tag} />&nbsp;{tag}</span>;
    });
  };
  
  return (
    <>
      <div id="headerRow" className="row" style={{"marginTop":"10px","marginBottom":"10px","position":"relative","zIndex":"1"}}>
        <div className={appData.topTitleClass}>
          <h1>Available Items</h1>
          <p>Scroll through items and add the ones you need to your cart. 
          Checkout when you are through.</p>
        </div>
        <div className="col">
          <button className="btn btn-secondary btn-sm" onClick={showCart}>
            <span className="badge rounded-pill bg-danger">
              {appData.cart.length}
            </span>&nbsp;Cart 
          </button>
        </div>
        <div className="col">
          <button className="btn btn-secondary btn-sm" onClick={toggleTags}>Tags</button>
        </div>
        <div className="col">
          <button className="btn btn-secondary btn-sm  float-end" onClick={checkout}>Checkout</button>
        </div>
      </div>
      <div className={appData.tagsShown}>
        <div className="col">
          {tagChoices()}
        </div>
      </div>
      <div onScroll={hideTop} style={{"width":"100%","overflowY":"auto","overflowX":"hidden","height":height}}>
        <ShowList />
      </div>
    </>
  );
}

export default Items;