import { useDataStore } from "./DataStoreProvider";
import Loading from "./Loading";

function Users() {
  const { appData, setAppData } = useDataStore();
  
  const setPosing = (event) => {
    const selectedIndex = event.target.options.selectedIndex;
    let   userId        = "";
    for (let i=0; i<appData.userList.length; i++) {
      if (i === selectedIndex) {
        setAppData({
          ...appData,
          posingAs : appData.userList[i],
        });
        
        break;
      }
    }    
  };
  
  if (appData.userList.length) {
    const choices = appData.userList.map((user) => {
      return <option value={user.id} key={user.id} data-id={user.id}>{user.userName}</option>;
    });
    return <select id="userChoices" className="form-control" onChange={setPosing}>{choices}</select>;
  }
  else {
    const url = appData.endpoint + 'login?user_id=' + appData.userId;
    fetch(url, {
      method : "GET",
      cache : "no-cache",
      headers : {
        Authorization : appData.token
      }                
    })
    .then((res) => {
      if (200 === res.status) {
        res.json().then((data) => {
          setAppData({
            ...appData,
            userList : data.userList,
          });
        })
        .catch((err) => console.error(err));
      }
      else if (401 === res.status) {
        alert('Required login missing or expired');
        setAppData({
          ...appData,
          page : 'welcome',
        });
      }
    })
    .catch((err) => console.error(err));
    
    return <Loading />;
  }
}


function AdminOptions() {
  const { appData, setAppData } = useDataStore();
  
  const formAction = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    setAppData({ ...appData, [key]: val });
  };  
  
  const orders = () => {
    setAppData({
      ...appData,
      page : "orders",
    });
  };
  
  const intakes = () => {
    setAppData({
      ...appData,
      page : "intakes",
      intakes : [],
    });
  };
  
  const logInAs = () => {
    setAppData({
      ...appData,
      userId : appData.posingAs.id,
      token : appData.posingAs.token,
      email : appData.posingAs.email,
      phone : appData.posingAs.phone,
      admin : appData.posingAs.admin,
      userName : appData.posingAs.userName,
      preferComms : appData.posingAs.preferComms,
      address : appData.posingAs.address,
      town : appData.posingAs.town,
      car : appData.posingAs.car,
    });
  };
  
  const clearBin = () => {
    if ('clearBinNum' in appData && parseInt(appData.clearBinNum) >= 0) {
      
      setAppData({
        ...appData,
        page : "loading",
      });
    
      const url = appData.endpoint + 'item';
      fetch(url, {
        method : "DELETE",
        body : JSON.stringify({
          user_id : appData.userId,
          token : appData.token,
          bin : appData.clearBinNum,
        }),     
      })
      .then((res) => {
        if (200 === res.status) {
          const filteredItems = appData.items.reduce((prev, curr) => {
            if (curr.bin !== appData.clearBinNum) {
              prev.push(curr);
            }
            
            return curr;
          }, []);
          
          setAppData({
            ...appData,
            items : filteredItems,
            clearBinNum : "",
          });
        }
      })
      .catch((err) => console.error(err));  
    }
  
  };
    
  if (appData.admin) {
    return (
      <>
        <div className="row" style={{"marginTop":"20px"}}>
          <div className="col-6 mx-auto">
            <button className="btn btn-primary btn-lg" onClick={orders}>Orders</button>
          </div>
          <div className="col-6 mx-auto">
            <button className="btn btn-primary btn-lg float-end" onClick={intakes}>Intake</button>
          </div>
        </div>
        <div className="row" style={{"marginTop":"20px"}}>
          <div className="col-3">
            Log in as:
          </div>
          <div className="col-4">
            <Users />
          </div>
          <div className="col">
            <button className="btn btn-warning" onClick={logInAs}>Go</button>
          </div>
        </div>
        <div className="row" style={{"marginTop":"20px"}}>
          <div className="col-3">
            Clear out bin: 
          </div>
          <div className="col-4">
            <input type="number" min="0" step="1" name="clearBinNum" className="form-control" onChange={formAction} />
          </div>
          <div className="col">
            <button className="btn btn-warning" onClick={clearBin}>Clear</button>
          </div>          
        </div>
      </>
    );
  }
  else {
    return "";
  }
}

function DonateOrReceive() {
  const { appData, setAppData } = useDataStore(); 
  
  const donate = (event) => {
    event.preventDefault();
    
    setAppData({
      ...appData,
      page : 'hold',
      intake : "",
      items : [],
      allItems : [],
    });            
  };
  
  const receive = (event) => {
    event.preventDefault();
    
    setAppData({
      ...appData,
      page : 'items',
      tagsChosen : [],
      items : [],
      allItems : [],
      cart : [],
    });
  };
  
  return (
    <>
      <div className="row" style={{"marginTop":"20px"}}>
        <div className="col-6 mx-auto">
          <button className="btn btn-primary btn-lg" onClick={donate}>Donate Items</button>
        </div>
        <div className="col-6 mx-auto">
          <button className="btn btn-primary btn-lg float-end" onClick={receive}>Receive Items</button>
        </div>
      </div>
    
      <AdminOptions />
    </>
  );
}

export default DonateOrReceive;