import { useDataStore } from "./DataStoreProvider";

function Hold() {
  const { appData, setAppData } = useDataStore(); 
  
  const willHold = (event) => {
    event.preventDefault();
    
    if (appData.address && appData.town) {
      setAppData({
        ...appData,
        page : 'inputItems',
        itemName : "",
        status : 'will_hold',
        location : 'donor',
      });        
    }
    else {
      setAppData({
        ...appData,
        page : 'address',
        text : `In order to arrange for picking up items at your residence, 
          we need to collect basic address information now. You can communicate more 
          detailed directions to your door with whomever comes by to pick items up; they 
          will contact you through this website and you can arrange times and send them 
          whatever information is needed directly at that time.`,
        status : 'will_hold',
        location : 'donor',
      });              
    }
  };
  
  const pickupDropoff = (event) => {
    event.preventDefault();
    
    if (appData.car || (appData.address && appData.town)) {
      setAppData({
        ...appData,
        page : 'inputItems',
        itemName : "",
        status : 'cannot_hold',
      });        
    }
    else if (!appData.address || !appData.town) {
      setAppData({
        ...appData,
        page : 'address',
        text : `We can not guarantee that someone will be able to pick up your items, or when 
          they might be able to do so. The success of our project relies on 
          community participation. 
          In order to attempt to arrange for picking up items at your residence, 
          we need to collect basic address information now. You can communicate more 
          detailed directions to your door with whomever comes by to pick items up; they 
          will contact you through this website and you can arrange times and send them 
          whatever information is needed directly at that time.`,
        status : 'cannot_hold',
      });              
    }
    else {
      setAppData({
        ...appData,
        page : 'inputItems',
        thumbnails : [],
        images : [],
        itemName : "",
        status : 'cannot_hold',
      });        
    }
  };

  return (
    <>
      <div style={{"marginTop":"10px"}}>
        Are you able to hold your item(s) until someone in need (or someone volunteeting
        to do delivery) picks them up? Our project currently has access to only limited 
        storage space for items which can be placed in bins. We do not have storage space 
        for larger items like furniture or bicycles. 
      </div>
      <div style={{"marginTop":"25px"}}>
        <button className="btn btn-primary" onClick={willHold}>
          Yes, I can hold the items for pickup. 
        </button>
      </div>
      <div style={{"marginTop":"25px"}}>
        <button className="btn btn-secondary" onClick={pickupDropoff}>
          No, unfortunately I need to get these items out of my space A.S.A.P. 
        </button>
      </div>
    </>
  );
}

export default Hold;