import { useDataStore } from "./DataStoreProvider";
import Loading from "./Loading";
import parse from "html-react-parser";

function ImageDiv(item) {
  const arrayKeys = Object.keys(item.images);
  return arrayKeys.map((k) => {
    const image  = item.images[k];
    const source = 'https://s3.amazonaws.com/images.trianglemutualaid.org/'
                 + image.prefix + '/' + image.filename;
                 
    return (
      <div className="col-sm-2 order-1" key={image.id}>
        <img src={source} alt="Item" style={{"width":"100%","maxWidth":"100%","height":"auto"}} id={item.id} />
      </div>
    );
  });
}

function ItemList() {
  const { appData, setAppData } = useDataStore();
  
  const reject = (event) => {
    fetch(appData.endpoint + 'intake', {
      method : "PUT",
      body : JSON.stringify({
        user_id : appData.userId,
        token   : appData.token,
        item_id : event.target.dataset.id,
        intake  : appData.intakeID,
        command : "reject",
      })
    })
    .then((res) => {
      if (200 === res.status) {
        const items = appData.items.reduce((prev, curr) => {
          if (curr.id !== event.target.dataset.id) {
            prev.push(curr);
          }
          return prev;
        }, []);
        
        if (items.length) {
          setAppData({
            ...appData,
            items : items,
          });                          
        }
        else {
          setAppData({
            ...appData,
            items : [],
            intakes : [],
            page : "intakes",
          });                          
        }
      }
      else if (401 === res.status) {
        alert('Required login missing or expired');
        setAppData({
          ...appData,
          token : "",
          items : [],
          intakeID : "",
          page : 'welcome',
        });
      }      
    })
    .catch((err) => console.error(err));
    event.target.innerHTML = '<img src="/loading-2.gif" alt="loading..." style="height: 40px; width: auto;"} />';
  };
  
  const holdItem = (event) => {
    fetch(appData.endpoint + 'intake', {
      method : "PUT",
      body : JSON.stringify({
        user_id : appData.userId,
        token   : appData.token,
        item_id : event.target.dataset.id,
        intake  : appData.intakeID,
        command : "hold",
      })
    })
    .then((res) => {
      if (200 === res.status) {
        const items = appData.items.reduce((prev, curr) => {
          if (curr.id !== event.target.dataset.id) {
            prev.push(curr);
          }
          return prev;
        }, []);
        
        if (items.length) {
          setAppData({
            ...appData,
            items : items,
          });                          
        }
        else {
          setAppData({
            ...appData,
            items : [],
            intakes : [],
            page : "intakes",
          });                          
        }
      }
      else if (401 === res.status) {
        alert('Required login missing or expired');
        setAppData({
          ...appData,
          token : "",
          items : [],
          intakeID : "",
          page : 'welcome',
        });
      }      
    })
    .catch((err) => console.error(err));
    event.target.innerHTML = '<img src="/loading-2.gif" alt="loading..." style="height: 40px; width: auto;"} />';
  };
  
  const inventoryItem = (event) => {
    fetch(appData.endpoint + 'intake', {
      method : "PUT",
      body : JSON.stringify({
        user_id : appData.userId,
        token   : appData.token,
        item_id : event.target.dataset.id,
        intake  : appData.intakeID,
        command : "inventory",
      })
    })
    .then((res) => {
      if (200 === res.status) {
        const items = appData.items.reduce((prev, curr) => {
          if (curr.id !== event.target.dataset.id) {
            prev.push(curr);
          }
          return prev;
        }, []);
        
        if (items.length) {
          setAppData({
            ...appData,
            items : items,
          });                          
        }
        else {
          setAppData({
            ...appData,
            items : [],
            intakes : [],
            page : "intakes",
          });                          
        }
      }
      else if (401 === res.status) {
        alert('Required login missing or expired');
        setAppData({
          ...appData,
          token : "",
          items : [],
          intakeID : "",
          page : 'welcome',
        });
      }      
    })
    .catch((err) => console.error(err));
    
    event.target.innerHTML = '<img src="/loading-2.gif" alt="loading..." style="height: 40px; width: auto;"} />';
  };
  
  const row = (item) => {  
    return (
      <div className="row" style={{"marginTop":"30px"}} key={item.id}>
        <div className="col-sm-4 order-3">
          <button 
            className="btn btn-primary btn-sm"
            data-id={item.id}
            style={{"marginRight":"10px"}}
            onClick={inventoryItem}
          >Inventory</button>
          <button 
            className="btn btn-secondary btn-sm"
            data-id={item.id}
            style={{"marginRight":"10px"}}
            onClick={holdItem}
          >Hold</button>
          <button 
            className="btn btn-secondary btn-sm"
            data-id={item.id}
            style={{"marginRight":"10px"}}
            onClick={reject}
          >Reject</button>
        </div>
        <div className="col-sm-4 order-2">
          <p><strong>{item.name}</strong></p>
          <p><strong>Location:</strong> {item.city}</p>
          <p>{item.description}</p>
        </div>
        <ImageDiv {...item} />
        <hr />
      </div>
    );        
  };  
  
  if ("no items" === appData.message) {
    return (
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col">
          Couldn't find items in this intake. 
        </div>
      </div>    
    );
  }
  else if ("loading" === appData.message) {
    return <Loading />;
  }
  else if (appData.items.length) {    
    return appData.items.map((item) => {
      return row(item);
    });
  }
  else {
    fetch('https://d27q0fbbumtk5d.cloudfront.net/items.json', {
        method : "GET",
        cache : "no-cache",
    })
    .then((res) => {
      if (200 === res.status) {
        res.json().then((data) => {
          const intake = appData.intakes.reduce((prev, curr) => {
            if (curr.id === appData.intakeID) {
              return curr;
            }
            else {
              return prev;
            }
          }, {});          
        
          const items = data.not_avail.reduce((prev, curr) => {
            if (intake.items.includes(curr.id)) {
              prev.push(curr);
            }
            
            return prev;            
          }, []);
        
          if (items.length) {
            setAppData({
              ...appData,
              items : items,
            });          
          }
          else {
            setAppData({
              ...appData,
              message : 'no items',
            });
          }
        
        });
      }
      else if (401 === res.status) {
        alert('Required login missing or expired');
        setAppData({
          ...appData,
          page : 'welcome',
        });
      }
    })
    .catch((err) => console.error(err)); 
  
    return <Loading />;
  }
  
}

function IntakeDetails() {
  const { appData, setAppData } = useDataStore();
  
  const intake = appData.intakes.reduce((prev, curr) => {
    if (curr.id === appData.intakeID) {
      return curr;
    }
    else {
      return prev;
    }
  }, {});
  
  const formatContact = (contact) => {
    if (contact.match(/@/)) {
      return '<a href="mailto:' + contact + '">' + contact + '</a>';
    }
    else if (contact.match(/\d{10}/)) {
      const c = String(contact);
      return '<a href="sms:+1' + contact + '">(' + c.substring(0, 3) + ') ' + c.substring(3, 6) + '-' + c.substring(6) + '</a>';
    }
  };
  
  const allInventoried = (event) => {
    fetch(appData.endpoint + 'intake', {
      method : "PUT",
      body : JSON.stringify({
        user_id : appData.userId,
        token   : appData.token,
        intake  : appData.intakeID,
        command : "complete",
      })
    })
    .then((res) => {
      if (200 === res.status) {
        setAppData({
          ...appData,
          items : [],
          intakes : [],
          page : "intakes",
        });                                
      }
      else if (401 === res.status) {
        alert('Required login missing or expired');
        setAppData({
          ...appData,
          token : "",
          items : [],
          intakeID : "",
          page : 'welcome',
        });
      }      
    })
    .catch((err) => console.error(err));
    
    event.target.innerHTML = '<img src="/loading-2.gif" alt="loading..." style="height: 40px; width: auto;"} />';    
  };
    
  return (
    <>
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col">
          <h1>Intake Details</h1>
        </div>
      </div>
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-sm-2" style={{"marginTop":"10px"}}>
          Intake ID {intake.id}
          <br />
          <button className="btn btn-success" style={{"marginTop":"15px"}} onClick={allInventoried}>
          All Inventoried</button>
        </div>
        <div className="col-sm-3" style={{"marginTop":"10px"}}>
          {intake.donor.name}
        </div>
        <div className="col-sm-1" style={{"marginTop":"10px"}}>
          {intake.donor.address}, {intake.donor.town}
        </div>
        <div className="col-sm-2" style={{"marginTop":"10px"}}>
          Has car? {intake.donor.car ? 'Yes' : 'No'}
        </div>
        <div className="col-sm-2" style={{"marginTop":"10px"}}>
          {intake.items.length} items
        </div>   
        <div className="col-sm-2" style={{"marginTop":"10px"}}>
          {parse(formatContact(intake.donor.contact))}
        </div>
      </div>
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col">
          {intake.availability}
        </div>
        <div className="col">
          <span className="badge bg-info text-dark" style={{"fontSize":"1.2em"}}>Status: {intake.status.replace(/_/, ' ')}</span>
        </div>
      </div>
      <ItemList />
    </>
  );
}

export default IntakeDetails;