import { useDataStore } from "./DataStoreProvider";

function NotImplemented() {

  const { appData, setAppData } = useDataStore();

  const ok = (event) => {
    event.preventDefault();
    
    setAppData({
      ...appData,
      page : 'donateOrReceive',
    });
  };

  return (
    <>
      <div style={{"marginTop":"10px"}}>
        <h1>Not Implemented</h1>
        <p>Sorry, we haven't completed work on this section yet. Please check back again soon.</p>
      </div>
      <div style={{"marginTop":"10px"}}>
          <button 
            className="btn btn-primary"
            onClick={ok}
          >OK</button>
      </div>
    </>
  );
}

export default NotImplemented;