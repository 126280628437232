import React, { createContext, useState, useContext } from "react";

const DataStore = createContext();
export const useDataStore = () => useContext(DataStore);

export default function DataStoreProvider({ children }) {

  /* change endpoint here and only hear to select version of back-end code to run against */
  const [appData, setAppData] = useState({ 
    token: "", 
    email: "", 
    phone: "",
    userId: "",
    loading: false, 
    page : "welcome",
    endpoint : 'https://j1hfjomnhk.execute-api.us-east-1.amazonaws.com/10/',
    thumbnails : [],
    images : [],
    items : [],
    message : "",    
    cart : [],
    admin : false,
    location : "Eno House",
    bin : -1,
    orders : [],
    next : window.location.reload,
    topTitleClass : "col-md-8",
    agree : false,
    posingAs : "",
    userList : [],
    tagsShown : "row d-none",
    tagsChosen : [],
    tags : [],
   });

  return (
    <DataStore.Provider value={{ appData, setAppData }}>
      {children}
    </DataStore.Provider>
  );
}