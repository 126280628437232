import { useDataStore } from "./DataStoreProvider";
import { useEffect } from 'react';


function Confirm() {
  const { appData, setAppData } = useDataStore(); 
  
  useEffect(() => {
    window.scroll(0,0);
  });

  const formAction = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    setAppData({ ...appData, [key]: val });
  };
  
  const next = (event) => {
    event.preventDefault();
    
    if (!appData.code) {
      document.getElementById('code').classList.add("is-invalid");
      return false;
    }
    
    setAppData({
      ...appData,
      page : "loading"
    });
    
    const url = appData.endpoint + 'login?id=' + appData.userId + '&code=' + appData.code;
    fetch(url, {
      method : "GET",
    })
    .then((res) => {
      res.json().then((data) => {
        if (200 === res.status) {
          setAppData({
            ...appData,
            page : 'donateOrReceive',
            token : data.token,
            userName : data.userName,
            email : data.email,
            phone : data.phone,
            preferComms : data.preferComms,
            address : data.address,
            town : data.town,
            car : data.car,
            code : "",
            admin : data.admin,
          });        
        }
        else if (401 === res.status || 400 === res.status) {
          alert(data.message);
          setAppData({
            ...appData,
            page : 'welcome',
            code : "",
          });                
        }
        else {
          alert(data.message);
          setAppData({
            ...appData,
            page : 'confirm',
          });        
        }      
      });
    })
    .catch((err) => console.error(err))  
  };
  
  return (
    <>
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-4" style={{"marginBottom":"10px"}}>
          Enter the code emailed or texted to you:
        </div>
        <div className="col-md-8" style={{"marginBottom":"10px"}}>
          <input 
            type="text"
            name="code"
            id="code"
            value={appData.code}
            onChange={formAction}
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <button className="btn btn-primary" onClick={next}>Continue</button>
        </div>
      </div>
    </>
  );
}

export default Confirm;